import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Html5QrcodeScanner } from "html5-qrcode";
import Html5QrcodePlugin  from "./Html5QrcodePlugin";
import ResultContainerPlugin from './ResultContainerPlugin';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';


export default class CompanyManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			gotolinks:[],
			gotolinksFilter:[],
			allCategories:[],
			keyNames:new Map(),
			selectedKeyNames:'',
			categories:[],
			subcategories:[],
			tags:[],
			cusCategoryName:'',
			addEditCategory:false,
			addEditSubCategory:false,
			showCompany:true,
			showBranch:false,
			showGroup:false,
			urltype:'Static',
			gtLinkTypeSyntax:'',
			page:0,
			rowsPerPage:10,
			filterTags:'',
			
			companies:[],
			branches:[],
			groups:[],
			companiesFilter:[],
		}
    }
	
	componentDidMount() {
		this.loadScript();
		this.getCompanies();
		this.getBranches();
		this.getGroups();
	}
	
	loadScript() {
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
	}
	
	
	getCompanies=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'companies';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			
			this.setState({companies:data});
			
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
        })
	}
	
	getBranches=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'branches';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({branches:data});
        })
	}
	
	getGroups=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'groups';
		let data = [];
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({groups:data});
        })
	}
	
	dataFilter=() => {
		let companiesFilter = [];
		let that = this;
		let tags = [];
		this.state.companies.map(function(row,i) {
			
			/* tags.push(row.tags);
			
			if(that.state.filterType && that.state.filterType != row.type){
				return false;
			}

			if(that.state.filterCategory && that.state.filterCategory != row.category){
				return false;
			}
			
			if(that.state.filterSubCategory && that.state.filterSubCategory != row.subcategory){
				return false;
			}
			
			if(that.state.filterTags && that.state.filterTags != row.tags){
				return false;
			} */

			companiesFilter.push(row);
		});
		this.setState({companiesFilter,page:0});
	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	showSection=(type) => {
		this.setState({showCompany:false,showBranch:false,showGroup:false});
		this.setState({[type]:true});
	}
	
	addCompany=() => {
		
		let newCompany = this.state.newCompany;
		let assignGroup = this.state.assignGroup;
		let description = this.state.newCompanyDescription;
		if(newCompany){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-company';
			let uid = ulid();
			let faciliity_id = ulid();
			let formData = new FormData();
			formData.append('uid', uid);
			formData.append('faciliity_id', faciliity_id);
			formData.append('name', newCompany);
			formData.append('group_id', assignGroup);
			formData.append('description', description);
			
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getCompanies();
					$('#addCompany').modal('hide');
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	addGroup=() => {
		let groupName = this.state.newGroupName;
		let description = this.state.newGroupDescription;
		if(groupName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-group';
			let uid = ulid();
			let formData = new FormData();
			formData.append('uid', uid);
			formData.append('name', groupName);
			formData.append('description', description);
			
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getGroups();
					$('#manageGroup').modal('hide');
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	addBranch=() => {
		let newBranch = this.state.newBranch;
		let assignCompany = this.state.assignCompany;
		if(newBranch){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-branch';
			let uid = ulid();
			let formData = new FormData();
			formData.append('uid', uid);
			formData.append('name', newBranch);
			formData.append('company', assignCompany);
			
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getBranches();
					$('#addBranch').modal('hide');
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteCompany=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-company/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getCompanies();
				this.getBranches();
				alert(response.data);
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteGroup=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-group/'+id;
			axios({
				method:'delete',
				url:url,
			})
			.then(response => {
				this.getGroups();
				alert(response.data);
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteBranch=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-branch/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getBranches();
				alert(response.data);
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	editCompany=(val) => {
		let eId = val.id;
		let eCompany = val.name;
		let eAssignGroup = val.group_id;
		let eCompanyDescription = val.description;
		
		this.setState({eId,eCompany,eAssignGroup,eCompanyDescription});
		
		$('#editcompny').modal('show');
	}
	
	updateCompany=() => {	
		let eId = this.state.eId;
		let newCompany = this.state.eCompany;
		let assignGroup = this.state.eAssignGroup;
		let description = this.state.eCompanyDescription;
		if(newCompany){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-company';
			let formData = new FormData();
			formData.append('id', eId);
			formData.append('name', newCompany);
			formData.append('group_id', assignGroup);
			formData.append('description', description);
			
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getCompanies();
					$('#editcompny').modal('hide');
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	editBranch=(val) => {
		let eId = val.id;
		let eBranch = val.name;
		let eAssignCompany = val.company;
		
		this.setState({eId,eBranch,eAssignCompany});
		
		$('#editBranch').modal('show');
	}
	
	UpdateBranch=() => {	
		let eId = this.state.eId;
		let eBranch = this.state.eBranch;
		let eAssignCompany = this.state.eAssignCompany;
		if(eBranch){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-branch';
			let formData = new FormData();
			formData.append('id', eId);
			formData.append('name', eBranch);
			formData.append('company', eAssignCompany);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getBranches();
					$('#editBranch').modal('hide');
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	editGroup=(val) => {
		let eId = val.id;
		let eGroupName = val.name;
		let eGroupDescription = val.description;
		this.setState({eId,eGroupName,eGroupDescription});
		
		$('#editGroup').modal('show');
	}
	
	updateGroup=() => {	
		let eId = this.state.eId;
		let eGroupName = this.state.eGroupName;
		let eGroupDescription = this.state.eGroupDescription;
		if(eGroupName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-group';
			let formData = new FormData();
			formData.append('id', eId);
			formData.append('name', eGroupName);
			formData.append('description', eGroupDescription);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getGroups();
					$('#editGroup').modal('hide');
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	
	render() {

		const {gotolinks,gotolinksFilter,allCategories,categories,subcategories,addEditCategory,addEditSubCategory,cusCategoryName,cusSubCategoryName,keyNames,tags,filterTags,companies, companiesFilter,groups,branches,showCompany,showBranch,showGroup} = this.state;
		//console.log('branches->',branches);
		let href = window.location.href.split('?')[0];
		let groupsOption = groups.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let companyOption = companies.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		

		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e9e9e9',
			color: '#000;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		  },
		}));
		
		return (
			<div>
				<aside className="sidebar">
				  <div className="toggle toggle-btn">
					<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
						<span></span>
					</a>
				  </div>
				  <div className="side-inner align-items-end" >
					<div className="sidebar-content">
						<div className="nav-content">
							<h5 className="page-title">Compnay Filter</h5>
							<div className="panel-btn">
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterCategory" onChange={this.getValue}>
										<option value={''}>Select Group</option>
										{groupsOption}
									</select>
								</div>						
							</div>
							
							<div className="left-link-box">
								<h5 className="page-title">Company Management</h5>
								<button className="btn btn-header-link" onClick={()=>this.showSection('showGroup')}>Manage Group</button>
								<button className="btn btn-header-link" onClick={()=>this.showSection('showCompany')}>Manage Company</button>
								<button className="btn btn-header-link" onClick={()=>this.showSection('showBranch')}>Manage Branches</button>
								
								{/* <button className="btn btn-header-link" data-toggle="modal" data-target="#manageGroup" data-dismiss="modal">Manage Group</button>
								
								<button className="btn btn-header-link" data-toggle="modal" data-target="#addGotoLink" data-dismiss="modal">Add New Company</button> */}
							</div>

						</div>
					</div>
				  </div>
				</aside>
				
				<div id="page-content-wrapper" className="workflow-manager">
					{showCompany ? 
					<div className="company-list">
						<div className="row">
							<div className="col-md-12 mb-4">
								<h4>Company Manager</h4>
								<button className="btn btn-primary btn-cm" data-toggle="modal" data-target="#addCompany" data-dismiss="modal">Add New</button>
							</div>
						</div>
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
							<TableContainer>
							  <Table size="small" aria-label="a dense table">
								<TableHead>
								  <TableRow>
									<StyledTableCell>Group</StyledTableCell>
									<StyledTableCell>Name</StyledTableCell>
									<StyledTableCell>FaciliityID</StyledTableCell>
									{/* <StyledTableCell>Branches</StyledTableCell> */}
									<StyledTableCell>Description</StyledTableCell>
									
									<StyledTableCell>&nbsp;</StyledTableCell>
									
								  </TableRow>
								</TableHead>
								<TableBody>
								  {companiesFilter.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
									let groupName = '';
									groups.map(function(gp,i) {
										if(gp.id == row.group_id){
											groupName = gp.name;
										}
									})
									return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									  <TableCell className="gt-cat">{groupName}</TableCell>
									  <TableCell className="gt-name">{row.name}</TableCell>
									  <TableCell className="gt-name">{row.faciliity_id}</TableCell>
										  {/*  <TableCell className="gt-name">{row.branches}</TableCell> */}
									  <TableCell className="gt-description"><p>{row.description}</p></TableCell>
									  
									  <TableCell>
									  <div className="d-flex">
									  <button onClick={()=>this.editCompany(row)} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									  <button onClick={() => { if (window.confirm('Are you sure you want to delete this Company?')) this.deleteCompany(row.id) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									  </div>
									  </TableCell>
									  
									</TableRow>)
								  })}
								</TableBody>
							  </Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								component="div"
								count={companiesFilter.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
							  />
						</Paper>
					</div>
					:null}
					
					{showBranch ? 
					<div className="group-list">
						<div className="row">
							<div className="col-md-12 mb-4">
								<h4>Branch Manager</h4>
								<button className="btn btn-primary btn-cm" data-toggle="modal" data-target="#addBranch" data-dismiss="modal">Add New</button>
							</div>
						</div>
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
							<TableContainer>
							  <Table size="small" aria-label="a dense table">
								<TableHead>
								  <TableRow>
									<StyledTableCell>Name</StyledTableCell>
									<StyledTableCell>Company</StyledTableCell>
									<StyledTableCell>&nbsp;</StyledTableCell>
								</TableRow>
								</TableHead>
								<TableBody>
								  {branches.map((row) => {
									return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									  <TableCell className="gt-name">{row.name}</TableCell>
									  <TableCell className="gt-name">{row.company_name}</TableCell>
									  
									  <TableCell>
									  <div className="d-flex">
									  <button onClick={()=>this.editBranch(row)} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									 
									  <button onClick={() => { if (window.confirm('Are you sure you want to delete this Branch?')) this.deleteBranch(row.id) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									  </div>
									  </TableCell>
									  
									</TableRow>)
								  })}
								</TableBody>
							  </Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[15, 20, 50, 100]}
								component="div"
								count={branches.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
							  />
						</Paper>
					</div>
					:null}
					
					{showGroup ? 
					<div className="group-list">
						<div className="row">
							<div className="col-md-12 mb-4">
								<h4>Group Manager</h4>
								<button className="btn btn-primary btn-cm" data-toggle="modal" data-target="#manageGroup" data-dismiss="modal">Add New</button>
							</div>
						</div>
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
							<TableContainer>
							  <Table size="small" aria-label="a dense table">
								<TableHead>
								  <TableRow>
									<StyledTableCell>Name</StyledTableCell>
									<StyledTableCell>FaciliityID</StyledTableCell>
									<StyledTableCell>Description</StyledTableCell>
									<StyledTableCell>&nbsp;</StyledTableCell>
								</TableRow>
								</TableHead>
								<TableBody>
								  {groups.map((row) => {
									return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									  <TableCell className="gt-name">{row.name}</TableCell>
									  <TableCell className="gt-name">{row.uid}</TableCell>
									  <TableCell className="gt-description"><p>{row.description}</p></TableCell>
									  
									  <TableCell>
									  <div className="d-flex">
									  <button onClick={()=>this.editGroup(row)} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									  <button onClick={() => { if (window.confirm('Are you sure you want to delete this Group?')) this.deleteGroup(row.id) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									  </div>
									  </TableCell>
									  
									</TableRow>)
								  })}
								</TableBody>
							  </Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[15, 20, 50, 100]}
								component="div"
								count={groups.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
							  />
						</Paper>
					</div>
					:null}
					
				</div>
				
				<div className="modal" id={"manageGroup"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add New Company Group</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
							
								<div className={'group-add'}>
									<label>Name:</label>
									<input className="form-control" name="newGroupName" type="text" onChange={this.getValue}/>
									
									<label>Description:</label>
									<textarea className="form-control" name="newGroupDescription" onChange={this.getValue} type="text" maxLength={550} ></textarea>
								</div>
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addGroup()} type="button" className="btn btn-primary float-right mr-1" >Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"editGroup"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Group</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
							
								<div className={'group-add'}>
									<label>Name:</label>
									<input className="form-control" name="eGroupName"  value={this.state.eGroupName} type="text" onChange={this.getValue}/>
									
									<label>Description:</label>
									<textarea className="form-control" name="eGroupDescription"  value={this.state.eGroupDescription} onChange={this.getValue} type="text" maxLength={550} ></textarea>
								</div>
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.updateGroup()} type="button" className="btn btn-primary float-right mr-1" >Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'addCompany'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Add New Company</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Name: </label>
								<input className="form-control" name="newCompany" type="text" onChange={this.getValue}/>
								
								<label> Group: </label>
								<select className="form-control" name="assignGroup" onChange={this.getValue}>
									<option value={''}>Select Group</option>
									{groupsOption}
								</select>
								
								{/* <label> Faciliity: </label>
								<input className="form-control" name="newFaciliity" type="text" placeholder="Name" onChange={this.getValue}/> */}
								
								<label>Description:</label>
								<textarea className="form-control" name="newCompanyDescription" onChange={this.getValue} type="text" maxLength={550} ></textarea>

							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.addCompany()}>Save</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'addBranch'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Add New Branch</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Name: </label>
								<input className="form-control" name="newBranch" type="text" onChange={this.getValue}/>
								
								<label> Company: </label>
								<select className="form-control" name="assignCompany" onChange={this.getValue}>
									<option value={''}>Select Company</option>
									{companyOption}
								</select>
								
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.addBranch()}>Save</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'editBranch'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Edit Branch</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Name: </label>
								<input className="form-control" name="eBranch" value={this.state.eBranch} type="text" onChange={this.getValue}/>
								
								<label> Company: </label>
								<select className="form-control" name="eAssignCompany" value={this.state.eAssignCompany} onChange={this.getValue}>
									<option value={''}>Select Company</option>
									{companyOption}
								</select>
								
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.UpdateBranch()}>Save</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'editcompny'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Edit Company</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Name: </label>
								<input className="form-control" name="eCompany" value={this.state.eCompany} type="text" onChange={this.getValue}/>
								
								<label> Group: </label>
								<select className="form-control" name="eAssignGroup" value={this.state.eAssignGroup} onChange={this.getValue}>
									<option value={''}>Select Group</option>
									{groupsOption}
								</select>
								
								{/* <label> Faciliity: </label>
								<input className="form-control" name="newFaciliity" type="text" placeholder="Name" onChange={this.getValue}/> */}
								
								<label>Description:</label>
								<textarea className="form-control" name="eCompanyDescription" value={this.state.eCompanyDescription} onChange={this.getValue} type="text" maxLength={550} ></textarea>

							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.updateCompany()}>Save</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		);
		
	}
}
