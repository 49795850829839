import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'; 
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

export default class UserManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			users:[],
			companies:[],
			allBranches:[],
			branches:[],
			userName:'',
			page:0,
			rowsPerPage:5,
			userRole:'Subscriber',
		}
    }
	
	componentDidMount() {
		this.getCompanies();
		this.getUsers();
		this.getBranches();
	}
	
	getUsers=() => {
		
		let user = localStorage.getItem('username');
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'users';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			let usersArray = [];

			data.map(function(row,i) {
				if(row.username == 'admin'){
					return false;
				}
				usersArray.push(row);
			});	
			
			this.setState({users:usersArray});
			
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	getCompanies=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'companies';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({companies:data});
        })
	}
	
	userApprove=(id) => {
		
		let str = 'user_'+id;
		let val = 0;
		if($("input[name='"+str+"']").prop("checked") == true){
			val = 1;
			this.setState({[str]:true});
		}else{
			this.setState({[str]:false});
		}

		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'user-approve/'+id;
			let formData = new FormData();
			formData.append('val', val);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.getUsers();
			}).catch(error => {
				alert('error::'+ error);
			})
		}
    }
	
	updateRoleOption=(event) => {
		let userId = event.target.name;
		let res = event.target.value;
		if(userId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-user-role/'+userId;
			let formData = new FormData();
			formData.append('role', res);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.getUsers();
			}).catch(error => {
				alert('error::'+ error);
			})
		}
    }
	
	getEditUser=(id,username) => {
		this.setState({'userId':id,'userName':username});
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		
		if(name == 'userName'){
			res = res.replace(/([-,.€~!@#$%^&*()_+=`{}\[\]\|\\:;'<>])+/g, '');
			res = res.replaceAll(" ", "");
		}
		
		if(name == 'assignCompany'){
			this.getBranches2(res);
		}
		
		this.setState({[event.target.name]:res});
	}
	
	getBranches=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'branches';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({allBranches:data});
        })
	}
	
	getBranches2=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'branches-by-company/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({branches:data});
        })
	}
	
	addUser=() => {
		let userName = this.state.userName;
		let userPassword = this.state.userPassword;
		let userRole = this.state.userRole;
		let userEmail = this.state.userEmail ? this.state.userEmail : '';
		let assignCompany = this.state.assignCompany;
		let assignBranch = this.state.assignBranch;
		
		if(userName && userPassword){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-user';
			let formData = new FormData();
			formData.append('username', userName);
			formData.append('password', userPassword);
			formData.append('role', userRole);
			formData.append('email', userEmail);
			formData.append('company', assignCompany);
			formData.append('branch', assignBranch);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data.username){
					alert('The user added successfully!');
				}
				this.getUsers();
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	updateUser=() => {
		
		let userId = this.state.userId;
		let userName = this.state.userName;
		let userPassword = this.state.userPassword;
		
		if(userId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-user/'+userId;
			let formData = new FormData();
			formData.append('username', userName);
			formData.append('password', userPassword);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.getUsers();
				$("input[name='userPassword']").val('');
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	addNewUser=() => {
		$("input[name='userName']").val('');
		$("input[name='userEmail']").val('');
		$("input[name='userPassword']").val('');
	}
	
	deleteUser=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-user/'+id;
			let formData = new FormData();
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getUsers();
				if(response.data){
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	render() {
		const {users,companies,allBranches,branches} = this.state;
		
		let href = window.location.href.split('?')[0];
		
		let companyOption = companies.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let branchOption = branches.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		/* let rowHtml = '';
		if(users){
			let that = this;
			rowHtml = users.map(function(row,i) {
				if(row.username == 'admin'){
					return false;
				}
				
				return (<tr>
					<td className="w-name">{row.username}</td>
					<td>{row.email }</td>
					<td className="input-role-option">
					<select id={row.id} className="role-option" name="userRole" onChange={that.updateRoleOption} value={row.role}>
						<option value='SuperAdmin'>Super Admin</option>
						<option value='Admin'>Admin</option>
						<option value='Subscriber'>Regular User</option>
						<option value='General'>General User</option>
					</select>
					</td>
					<td><input type="checkbox" checked={row.status == 1 || that.state[row.id] ? 'checked' : ''} name={'user_'+row.id} onClick={()=>that.userApprove(row.id)} /></td>
					<td><button type="button" onClick={()=>that.getEditUser(row.id,row.username)} className="btn btn-edit-workflow" data-toggle="modal" data-target="#editWorkflow"><i className="fa fa-edit"></i></button><i onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) that.deleteUser(row.id) } } className="fa fa-trash"></i></td>
				</tr>);
			})
		} */
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e9e9e9',
			color: '#000;',
			textAlign: 'left',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		  },
		}));
		
		return (
			<div className="user-list">
				<div className="row">
					<div className="col-md-12">
						<h4>Login Users</h4>
					</div>
				</div>
				<div className="add-user">
					<Button variant="outlined" onClick={()=>this.addNewUser()} data-toggle="modal" data-target="#addUser">Add User</Button>
				</div>
				<div className="user-table">
					<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer>
						  <Table size="small" aria-label="a dense table">
							<TableHead>
							  <TableRow>
								<StyledTableCell style={{width: '20%'}}>UserName</StyledTableCell>
								<StyledTableCell style={{width: '20%'}}>Email</StyledTableCell>
								<StyledTableCell style={{width: '20%'}}>Role</StyledTableCell>
								<StyledTableCell style={{width: '10%'}}>Company</StyledTableCell>
								<StyledTableCell style={{width: '10%'}}>Branch</StyledTableCell>
								<StyledTableCell style={{width: '10%'}}>Approve</StyledTableCell>
								<StyledTableCell style={{width: '10%'}}></StyledTableCell>
							  </TableRow>
							</TableHead>
							<TableBody>
							  {users.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
								let companyName = '';
								companies.map(function(val,i) {
									if(row.company == val.id){
										companyName = val.name;								
									}
								})
								  
								let branchName = '';
								allBranches.map(function(val,i) {
									if(row.company == val.id){
										branchName = val.name;								
									}
								})									
								
								return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell>{row.username}</TableCell>
									<TableCell>{row.email ? row.email : 'example@gmail.com'}</TableCell>
									<TableCell>
									<Select
										value={row.role}
										name={row.id}
										onChange={this.updateRoleOption}
										style={{ width: 180,height: 28 }}
									>
									<MenuItem value='SuperAdmin'>Super Admin</MenuItem>
									<MenuItem value='Admin'>Admin</MenuItem>
									<MenuItem value='Subscriber'>Regular User</MenuItem>
									<MenuItem value='General'>General User</MenuItem>
									<MenuItem value='Scan'>Scan</MenuItem>
									</Select>
									</TableCell>
									<TableCell>{companyName}</TableCell>
									<TableCell>{branchName}</TableCell>
									<TableCell>
									<Checkbox name={'user_'+row.id} checked={row.status == 1 || this.state[row.id] ? 'checked' : ''} onClick={()=>this.userApprove(row.id)} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />
									</TableCell>
									<TableCell><button type="button" onClick={()=>this.getEditUser(row.id,row.username)} className="btn btn-edit-user" data-toggle="modal" data-target="#editWorkflow"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button><i onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) this.deleteUser(row.id) } } className="fa fa-trash"></i></TableCell>
								  
								</TableRow>)
							  })}
							</TableBody>
						  </Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							component="div"
							count={users.length}
							rowsPerPage={this.state.rowsPerPage}
							page={this.state.page}
							onPageChange={this.handleChangePage}
							onRowsPerPageChange={this.handleChangeRowsPerPage}
						  />
					</Paper>
				</div>
				<div className="modal" id={"editWorkflow"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal mds-description-modal">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="modal-title">Update username and password!</h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
							<label className="label-control"> UserName </label>
							<input className="form-control" name="userName" type="text" value={this.state.userName} onChange={this.getValue} />
							<label className="label-control"> Password </label>
							<input className="form-control" name="userPassword" type="password " onChange={this.getValue} />
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button type="button" onClick={()=>this.updateUser()} className="btn btn-info float-right mr-1" data-dismiss="modal">Update</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				<div className="modal" id={"addUser"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal mds-description-modal">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="modal-title">Add New User!</h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
							<label className="label-control"> UserName </label>
							<input id="username" className="form-control" name="userName" type="text" value={this.state.userName} onChange={this.getValue} />
							<label className="label-control"> Email </label>
							<input className="form-control" name="userEmail" type="email" onChange={this.getValue} />
							<label className="label-control"> Role </label>
							<select className="form-control role-option-2" name="userRole" value={this.state.userRole} onChange={this.getValue} >
								<option value='SuperAdmin'>Super Admin</option>
								<option value='Admin'>Admin</option>
								<option value='Subscriber'>Regular User</option>
								<option value='General'>General User</option>
								<option value='Scan'>Scan</option>
								
							</select>
							{this.state.userRole != 'SuperAdmin' ?
							<div>
							<label> Company: </label>
							<select className="form-control" name="assignCompany" onChange={this.getValue}>
								<option value={''}>Select Company</option>
								{companyOption}
							</select>
							</div>
							:null}
							
							{branches.length > 0 ? 
							<div>
							<label> Branch: </label>
							<select className="form-control" name="assignBranch" onChange={this.getValue}>
								<option value={''}>Select Branch</option>
								{branchOption}
							</select>
							</div>
							:null}
							<label className="label-control"> Password </label>
							<input className="form-control" name="userPassword" type="password" onChange={this.getValue} />
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button type="button" onClick={()=>this.addUser()} className="btn btn-info float-right mr-1" data-dismiss="modal">Add</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
			</div>
		);
		
	}
}
