import React, { Component } from 'react';
import $ from 'jquery';
import { copyImageToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import QRCode from "react-qr-code";

export default class QrGenerator extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			qrValue:''
		}
    }
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
	}
	
	qrGeneratorCode=()=>{
		let qrName = this.state.qrName;
		let qrValue = this.state.qrValue;
		if(qrValue){
			$('#qrCodePopup').modal('show');
		}
	}
	
	
	copyImageToClipboard = () => {
		const qrCodeElement = document.getElementById('qr-code-img');
		html2canvas(qrCodeElement).then((canvas) => {
		    const pngUrl = canvas.toDataURL('image/png');
		    const image = new Image();
		    image.src = pngUrl;
		    copyImageToClipboard(pngUrl).then(() => {
			}).catch((e) => {
			  console.log('Error: ', e.message);
			})
		});
	};
	  
	handleCopyQRCode = (fileName) => {
		const qrCodeElement = document.getElementById('qr-code-img');
		if (qrCodeElement) {
		  html2canvas(qrCodeElement).then((canvas) => {
			const image = canvas.toDataURL('image/png');
			const link = document.createElement('a');
			link.href = image;
			link.download = fileName+'.png';
			link.click();
		  });
		}
	};
	
	render() {
		const {qrName,qrValue} = this.state;
		let href = window.location.href.split('?')[0];
		return (
			<div className="container">
			
			<div className="row justify-content-center go-to-section mt-3">
				<div className="col-md-12 text-center"><h6>QR Generator</h6></div>
				<div className="col-md-8">
					<div className="row">
						<div className="col-md-5 ">
							<input type="text" name="qrName" className="form-control" placeholder="Enter Qr name" onChange={this.getValue}></input>
						</div>
						<div className="col-md-5 ">
							<input type="text" name="qrValue" className="form-control" placeholder="Enter Qr value" onChange={this.getValue}></input>
						</div>
						<div className="col-md-2 pl-lg-0 pl-md-0 mt-lg-0 mt-md-0 mt-2">
							<button type="submit" className="btn btn-primary btn-block" onClick={this.qrGeneratorCode}>Submit</button>
						</div>
					</div>
				</div>
			</div>
			{this.state.qrValue ? 
			<div className="modal" id={"qrCodePopup"} role="dialog">
				<div className="modal-dialog modal-lg custom-modal qr-code-modal">
					<div className="modal-content qr-code-modal">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						</div>
						<div className="modal-body text-center">
							<div ref={this.qrRef} id="qr-code-img" className='rq-code-canvas'>
								<QRCode size={150} value={this.state.qrValue}/>
							</div>
							<div className="qr-code-button">
								<button onClick={()=>this.copyImageToClipboard()} className="btn copy-code-btn" type="button"><i className="fa fa-copy" aria-hidden="true"></i></button>
								<button onClick={()=>this.handleCopyQRCode(this.state.qrName)} className="btn copy-code-btn" type="button"><i className="fa fa-download" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			:null}
			</div>
		);
	}
}
